/*
 * @Author: Gang Jiang 
 * @Date: 2025-01-10 10:11:56 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2025-02-13 22:07:57
 * 邀请码, url: {{ host }}/[selller|publisher]/referral
 */
<template>
  <div :class="'referral user-' + userType">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true" style="fill: var(--dark-1)">
        <use xlink:href="#icon-zuanshi"></use>
      </svg>
    </breadcrumbs>
    <div class="content">
      <section>
        <h2>Share your referral link or invitation code</h2>
        <label>Your referral link</label>
        <div class="flex">
          <artmisads-input disabled :value="link" />
          <artmisads-button-special class="btn" @click="copyToClipboard(link)">
            <i class="iconfont icon-copy2" />
            <span class="btn-text">Copy</span>
          </artmisads-button-special>
        </div>
        <p class="warning-tip">
          <i class="iconfont icon-a-warring-tianchong3" />Your friends can
          directly register to Artemis by clicking your link
        </p>
        <label>Your personalized invitation code</label>
        <div class="flex">
          <artmisads-input disabled :value="invitationCode" />
          <artmisads-button-special
            class="btn"
            @click="copyToClipboard(invitationCode)"
          >
            <i class="iconfont icon-copy2" />
            <span class="btn-text">Copy</span>
          </artmisads-button-special>
        </div>
        <p class="warning-tip">
          <i class="iconfont icon-a-warring-tianchong3" />Don’t forget to ask
          your friends filling this code when registering if they are visiting
          Artemis not through your link.
        </p>
      </section>
      <section class="rewards-plan">
        <h2>Referral Rewards Plan</h2>
        <p>Coming Soon!</p>
        <p>
          Artemis will give you bonus rewards for inviting new friends to us.
        </p>
      </section>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import userApi from '~/server/sign.js'
import { message } from 'ant-design-vue'
import { copyToClipboard } from '~/utils'
import breadcrumbs from '../seller/breadcrumbs.vue'

const route = useRoute()
const userType = route.path.indexOf('publisher/') != -1 ? 'publisher' : 'seller'
const breadcrumbsData = {
  title: 'Refer your friends',
  hasTitleIcon: true,
  color: 'var(--yellow-1)',
  des: 'Share your personalized referral link or code to invite your friends to Artemis. Both seller and publisher/creator are welcome.',
  links: [
    {
      name: 'Home',
      path: `/${userType}/products`,
    },
    {
      name: 'Refer Your Friends',
      path: `/${userType}/referral`,
    },
  ],
}
const link = ref('')
const invitationCode = ref('')

userApi.getInvitationCode().then((res) => {
  if (res.success) {
    invitationCode.value = res.data.invitationCode
    link.value =
      location.origin + '/sign-up?invitationCode=' + res.data.invitationCode
  } else {
    message.error('Fail to get invitation code, please try again.')
  }
})
</script>

<style lang="less" scoped>
.user-seller {
  padding: 0 16px 16px;
}
.referral {
  @media screen and (max-width: 1441px) {
    :deep(.breadcrumbs) {
      height: auto;
      .preface {
        display: block;
      }
      .des {
        height: 20px;
        margin-top: 4px;
        margin-left: 40px;
        line-height: 20px;
      }
    }
  }
  section {
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    background: #ffffff;
    box-shadow: var(--section-box-shadow-6);
    > h2 {
      height: 64px;
      font-size: 20px;
      font-weight: 600;
      margin-top: -16px;
      line-height: 64px;
      margin-right: -16px;
      color: var(--dark-1);
      border-bottom: 1px solid #e8e5ed;
    }
    > label {
      height: 18px;
      display: block;
      margin-top: 24px;
      font-weight: 500;
      line-height: 18px;
      color: var(--dark-2);
      letter-spacing: 0.3px;
    }
    .flex {
      display: flex;
      margin-top: 8px;
      max-width: 830px;
      align-items: center;
      justify-content: space-between;
      :deep(.ant-input) {
        height: 32px;
        font-size: 14px;
        line-height: 32px;
        color: var(--dark-1);
      }
    }
    .btn {
      height: 32px;
      cursor: pointer;
      padding: 0 16px;
      font-weight: 500;
      margin-left: 8px;
      line-height: 32px;
      border-radius: 8px;
      background: var(--purple-3);
      .iconfont {
        font-size: 20px;
        margin-right: 8px;
      }
      &:hover {
        background-color: var(--purple-2);
      }
      &:active {
        background-color: var(--purple);
      }
    }
  }
  .rewards-plan {
    > h2 {
      height: 28px;
      border: none;
      margin-top: 2px;
      line-height: 28px;
      margin-bottom: 4px;
    }
    > p {
      color: var(--dark-3);
    }
  }
}
</style>